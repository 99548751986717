//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isMatch, scrollIntoViewIfNeeded, transformEntityName } from '@/utils'
import { searchAncestor } from '@/utils/DOM'
import { multiSearch } from '@/utils/Common.ts'

import ScrollableArea from '@/components/UI/ScrollableArea'

import EnterKeybar from './EnterKeybar'

import { format } from 'quasar'
import { contactsStore, groupsStore, teamsStore } from '@/store'
import { Contact, Chat } from '@tada-team/tdproto-ts'

const { capitalize } = format

export default {
  name: 'SearchHints',
  components: {
    'scrollable-area': ScrollableArea,
    EnterKeybar,
  },
  props: {
    of: {
      type: String,
      default: '',
    },
    warning: {
      type: String,
      default: '',
    },
    lastSearchQueries: {
      type: Array,
      default: () => ([]),
    },
    currentChatSearch: {
      type: Boolean,
      default: false,
    },
    minQueryLength: {
      type: Number,
      default: 3,
    },
  },
  data () {
    return {
      listHeight: 'auto',
    }
  },
  watch: {
    of (value) {
      this.$nextTick(this.recalculateContentHeight)

      this.previousSelectedRow && this.previousSelectedRow.removeAttribute('data-selected')
      this.previousSelectedRow = null

      value.length >= 3 && this.$nextTick(this.selectFirstRow)
    },
    lastSearchQueries () {
      this.$nextTick(this.recalculateContentHeight)
    },
  },
  mounted () {
    this.of.length >= 3 && this.selectFirstRow()
    this.recalculateContentHeight()
  },
  computed: {
    enterKeybarText () {
      return teamsStore.getters.isSingleGroupTeam()
        ? this.$t('common.openProfile')
        : this.$t('header.openChat')
    },
    sections () {
      const contacts = {
        name: capitalize(this.$t('glossary.contact_plural')),
        entities: this.contacts,
      }
      const groups = {
        name: capitalize(this.$t('glossary.group_plural')),
        entities: this.groups,
      }
      const unfilteredSections = teamsStore.getters.isSingleGroupTeam()
        ? [contacts]
        : [contacts, groups]
      return unfilteredSections.filter(section => section.entities.length > 0)
    },
    contacts () {
      return this.filteredItems(contactsStore.getters.contactList)
    },
    groups () {
      return this.filteredItems(groupsStore.getters.list)
    },
    queryOrder () {
      const chatOnly = { name: this.$t('header.chatOnly'), chatOnly: 'true' }
      const everywhere = { name: this.$t('header.everywhere'), chatOnly: 'false' }
      const result = teamsStore.getters.isSingleGroupTeam()
        ? [chatOnly]
        : [chatOnly, everywhere]

      !this.currentChatSearch && result.reverse()

      return result
    },
    showLastQueries () {
      return this.of.length < this.minQueryLength && this.lastSearchQueries.length > 0
    },
    showSearchHints () {
      return this.of.length >= this.minQueryLength
    },
  },
  methods: {
    icon (entity) {
      if (entity instanceof Contact) { return contactsStore.getters.contactIcon(entity.jid) } else if (entity instanceof Chat) {
        return groupsStore.getters.icon(entity.jid, true)
      }
      return entity.icon
    },
    filteredItems (items) {
      const matchFn = searchString => entity => isMatch(entity.displayName, searchString)
      return multiSearch(searchString => items.filter(matchFn(searchString)), this.of, 'jid', true)
    },
    transformEntityName,
    selectFirstRow () {
      const { container } = this.$refs
      if (!container) return

      const firstRow = container.querySelector('.hint-wrapper')
      if (!firstRow) return

      this.setSelected(firstRow)
    },
    /** Using from parent component */
    getRowData () {
      const { container } = this.$refs
      if (!container) return

      const selectedRow = container.querySelector('[data-selected="true"]')
      if (!selectedRow) return

      const chatOnly = selectedRow.getAttribute('data-chat-only')
      if (chatOnly) return { chatOnly: chatOnly === 'true' }

      const jid = selectedRow.getAttribute('data-entity')
      if (jid) return { jid }

      const recentQuery = selectedRow.getAttribute('data-query')
      if (recentQuery) return { recentQuery }
    },
    /** Using from parent component */
    arrowMove (up) {
      const { container } = this.$refs
      if (!container) return

      const selectedRow = container.querySelector('[data-selected="true"]')
      if (!selectedRow) {
        this.selectFirstRow()
        return
      }

      const rows = container.getElementsByClassName('hint-wrapper')
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i]
        if (!row || row.getAttribute('data-selected') !== 'true') continue

        if (up) {
          const target = rows[i - 1] || rows[rows.length - 1] || rows[0]
          target && this.setSelected(target)
        } else {
          const target = rows[i + 1] || rows[0] || rows[rows.length - 1]
          target && this.setSelected(target)
        }
        break
      }
    },
    handleMouseOver (event) {
      // TODO add throttle
      const { target } = event
      if (!target) return

      const element = searchAncestor(target, this.searchAncestorFunction)
      if (element === this.$el) return

      this.setSelected(element, true)
    },
    setSelected (element, disableScroll) {
      this.previousSelectedRow && this.previousSelectedRow.removeAttribute('data-selected')

      if (element) {
        element.setAttribute('data-selected', 'true')
        this.previousSelectedRow = element

        !disableScroll && scrollIntoViewIfNeeded(element, 'scrollable-content')
      }
    },
    searchAncestorFunction (element) {
      if (!element) return false

      if (element.classList.contains('hint-wrapper') || element === this.$el) return true

      return false
    },
    recalculateContentHeight () {
      const { scrollableArea } = this.$refs
      if (!scrollableArea) return

      const maxHeight = 300
      const currentFrameHeight = scrollableArea.getContentHeight(true)
      this.listHeight = currentFrameHeight >= maxHeight ? (maxHeight + 'px') : 'auto'
    },
  },
}
