//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Velocity from 'velocity-animate'
import { defaultLogger } from '@/loggers'

import SearchColoredInput from '@/components/UI/SearchColoredInput'
import SearchHints from './SearchHints'
import SearchInChat from './SearchInChat'

import { createSVGComponent } from '@/components/UI/icons/Factory/index.jsx'

import {
  teamsStore,
  uiStore,
} from '@/store'

export default {
  name: 'GlobalSearchBar',

  components: {
    'search-input': SearchColoredInput,
    'search-hints': SearchHints,
    SearchInChat,

    'search-icon': createSVGComponent({ icon: 'search', size: 20 }),
  },

  props: {
    compact: Boolean,
    isSearchInCalendar: Boolean,
  },

  data () {
    return {
      searchString: '',
      hintWarning: '',
      isFocused: false,
      chatOnly: false,
      lastSearchQueries: [],
    }
  },

  computed: {
    showSearchInChatOnly () {
      return !teamsStore.getters.isSingleGroupTeam() && !this.isFocused && !this.search && !this.isSearchInCalendar
    },
    search () {
      return this.searchString.trim()
    },
    teamId () {
      return teamsStore.getters.currentTeam.uid
    },

    hasSelectedMessagesIds () {
      const hasSelected = uiStore.getters.selectedMessagesIds && uiStore.getters.selectedMessagesIds.length > 0

      return hasSelected
    },
  },

  watch: {
    teamId (newTeamId, oldTeamId) {
      if (newTeamId === oldTeamId) return
      this.searchString = ''
    },
  },

  created () {
    this.width = 300

    const value = localStorage.getItem('__chatOnlySearch')
    this.chatOnly = value === null ? false : (value === 'true')

    this.minQueryLength = (window.FEATURES || {}).min_search_length || 3
    this.lastSearchQueriesCount = 4
  },

  mounted () {
    let { input } = this.$refs
    if (!input) return

    input = input.getInputElement()
    if (!input) return

    input.addEventListener('keydown', event => {
      const key = event.keyCode || event.which
      if (key !== 38 && key !== 40) return

      const { hints } = this.$refs
      if (!hints || !hints.arrowMove) return

      event.preventDefault()
      hints.arrowMove(key === 38)
    })
  },

  methods: {
    openGlobalSearch (query, chatOnly) {
      query = query || this.search

      if (!this.compact) {
        if (query.length < this.minQueryLength) {
          const isRU = this.$i18n.locale === 'ru'

          if (isRU) {
            this.hintWarning = this.$t('header.hintWarning', { verb: this.$tc('glossary.should', this.minQueryLength), min: this.$tc('glossary.character', this.minQueryLength) })
            return
          }
          this.hintWarning = this.$t('header.hintWarning', { min: this.$tc('glossary.character', this.minQueryLength) })
          return
        }

        this.addLastSearchQuery(query)
      }

      const payload = {
        searchQuery: query || this.search,
        chatOnly: chatOnly === undefined ? this.chatOnly : chatOnly,
        minQueryLength: this.minQueryLength,
      }
      uiStore.actions.switchMiddleColumnInstance({ instance: 'GlobalSearch', payload })

      const { input } = this.$refs
      input && input.blur()
    },
    find () {
      const { hints } = this.$refs
      if (!hints) {
        this.openGlobalSearch()
        return
      }

      const data = hints.getRowData()
      if (!data) {
        this.openGlobalSearch()
        return
      }

      if ('chatOnly' in data) {
        this.openGlobalSearch(this.search, data.chatOnly)
        return
      }

      if ('recentQuery' in data) {
        this.openGlobalSearch(data.recentQuery)
        return
      }

      if ('jid' in data) {
        if (teamsStore.getters.isSingleGroupTeam()) {
          uiStore.actions.switchRightBarInstance({
            instance: 'contact-profile',
            payload: data.jid,
          })
        } else {
          this.$router.push({
            name: 'Chat',
            params: {
              teamId: teamsStore.getters.currentTeam.uid,
              jid: data.jid,
            },
          })
        }

        const { input } = this.$refs
        input && input.blur()
        return
      }
      this.openGlobalSearch()
    },
    onInput () {
      this.hintWarning = ''
    },
    onFocusChanged (value) {
      Velocity(
        this.$el,
        { width: value ? (this.width + 60) : this.width },
        { duration: 150 },
      )

      if (value) {
        this.restoreLastSearchQueries()
      } else {
        this.hintWarning = ''
      }
      this.isFocused = value
    },
    handleQueryDelete (query) {
      const index = this.lastSearchQueries.indexOf(query)
      if (index < 0) return

      this.lastSearchQueries.splice(index, 1)

      this.storeLastSearchQueries()
    },
    addLastSearchQuery (query) {
      if (typeof query !== 'string') return

      const maxQueryLength = 128
      if (query.length > maxQueryLength) {
        query = query.substring(0, maxQueryLength)
      }

      const index = this.lastSearchQueries.indexOf(query)
      index >= 0 && this.lastSearchQueries.splice(index, 1)

      this.lastSearchQueries.unshift(query)

      if (this.lastSearchQueries.length > this.lastSearchQueriesCount) {
        this.lastSearchQueries = this.lastSearchQueries.slice(0, this.lastSearchQueriesCount)
      }

      this.storeLastSearchQueries()
    },
    storeLastSearchQueries () {
      try {
        if (this.lastSearchQueries.length === 0) {
          localStorage.removeItem('__lastSearchQueries')
        } else {
          const marshal = JSON.stringify(this.lastSearchQueries)
          localStorage.setItem('__lastSearchQueries', marshal)
        }
      } catch (e) {
        defaultLogger.warn('[SearchBar.storeLastSearchQueries]', e)
      }
    },
    restoreLastSearchQueries () {
      this.lastSearchQueries = []
      try {
        const storageEntry = localStorage.getItem('__lastSearchQueries')
        if (storageEntry !== null) {
          const unmarshal = JSON.parse(storageEntry)
          Array.isArray(unmarshal) && (this.lastSearchQueries = unmarshal)
        }
      } catch (e) {
        defaultLogger.warn('[SearchBar.restoreLastSearchQueries]', e)
      }

      if (this.lastSearchQueries.length > this.lastSearchQueriesCount) {
        this.lastSearchQueries = this.lastSearchQueries.slice(0, this.lastSearchQueriesCount)
      }
    },
  },
}
