//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ChatOnlyToggle',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    value (on) {
      try {
        localStorage.setItem('__chatOnlySearch', on)
      } catch (e) {
        console.warn(`[SearchInChat err]: ${e}`)
      }
    },
  },
}
