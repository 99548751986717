//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'EnterKeybar',
  props: {
    text: {
      type: String,
      required: true,
    },
    icon: {
      type: Boolean,
      default: true,
    },
  },
}
